<template>
  <div class="about">
    <div class="topBar">
      <van-nav-bar
        title="自我评价"
        left-text="返回"
        right-text="保存"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      />
    </div>

    <!--  -->
    <div v-if="isDetail">
      <!-- 文本框 -->
      <div class="editor">
        <div style="border: 1px solid #ccc; margin-top: 10px">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editorRef"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 500px; overflow-y: hidden"
            v-model="question"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated"
            @onChange="handleChange"
          />
        </div>
      </div>

      <!-- 附加图片 -->
      <div class="additional">
        <div class="addBtn">
          <van-button type="primary" @click="addImg">附加图片</van-button>
        </div>
        <div class="addImg" v-if="showAddImage">
          <van-field label="附加图片">
            <template #input>
              <van-uploader
                v-model="ImagesList"
                multiple
                :max-count="1"
                :before-read="beforeRead"
              />
            </template>
          </van-field>
        </div>
      </div>
    </div>

    <!--  -->
    <div v-if="!isDetail" class="editor">
      <div v-html="selfEvalContent"></div>
      <div class="picBox" v-if="imgPath">
        <van-image width="100%" height="100%" :src="prefix(imgPath)" />
      </div>
    </div>

    <!-- 切图工具 -->
    <div class="cut">
      <van-popup
        v-model:show="showCut"
        position="bottom"
        :style="{ height: '100%' }"
      >
        <vue-cropper
          v-if="isShowCro"
          :aspect-ratio="option.cropperAspectWH"
          ref="cropper"
          :src="option.imgSrc"
          :ready="option.cropImage"
          :zoom="option.cropImage"
          :cropmove="option.cropImage"
          style="width: 100%; height: 100%"
        ></vue-cropper>

        <!-- <button @click="getData">获取截图</button>
        <button @click="() => (showCut = !showCut)">
          {{ showCut ? "隐藏" : "展示" }}
        </button> -->
        <div class="fnBTn">
          <span @click="cancel">取消</span>
          <span @click="deteFn">确定</span>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script setup>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { useRouter, useRoute } from "vue-router";
import { shallowRef, ref, onBeforeUnmount } from "vue";
import { Toast } from "vant";
import { uploadImgBase } from "@/service/upload.js";
import { apiEval, apiCaseEvaInfo } from "@/service/student.js";
import { prefix } from "@/common/js/utils.js";

const router = useRouter();
const route = useRoute();

let question = ref();
// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();
let mode = ref("default");
const editorConfig = { placeholder: "请输入内容...", MENU_CONF: {} };
editorConfig.MENU_CONF["uploadImage"] = {
  base64LimitSize: 999999999 * 1024,
};
// 富文本配置
const toolbarConfig = {
  toolbarKeys: [
    "bold", // 加粗
    "italic", // 斜体
    // "through", // 删除线
    "underline", // 下划线
    "bulletedList", // 无序列表
    "numberedList", // 有序列表
    "color", // 文字颜色
    // "insertLink", // 插入链接
    // "fontSize", // 字体大小
    // "lineHeight", // 行高
    "delIndent", // 缩进
    "indent", // 增进
    // "deleteImage", //删除图片
    "divider", // 分割线
    // "uploadImage", // 上传图片
    // "insertImage", // 网络图片
    "insertTable", // 插入表格
    "justifyLeft", // 左对齐
    "justifyJustify", // 两端对齐
    "justifyCenter", // 居中对齐
    "justifyRight", // 右对齐
    "undo", // 撤销
    "redo", // 重做
    // "clearStyle", // 清除格式
    // "fullScreen", // 全屏
  ],
};

// 组件显示隐藏状态
let showAddImage = ref(false);
let showCut = ref(false);
let isDetail = ref(false);

let selfEvalContent = ref("");
let ImagesList = ref([]);
let imgPath = ref("");

const getCaseEvaInfo = async () => {
  let res = await apiCaseEvaInfo({ case_id: route.query.case_id });
  if (!res.data) {
    isDetail.value = true;
  } else {
    selfEvalContent.value = res.data.evaluate;
    imgPath.value = res.data.image_path;
  }
};
getCaseEvaInfo();

// 富文本输入内容
const handleChange = (e) => {
  if (e.getText()) {
    selfEvalContent.value = e.getHtml();
  }
};

const addImg = () => {
  showAddImage.value = true;
};

const beforeRead = (file, detail) => {
  if (file.type != "image/jpeg" && file.type != "image/png") {
    Toast("请上传 jpeg或者png 格式图片");
  } else {
    showCut.value = true;
    imageToBase64(file, detail);
    // uploadImgBase()
    // 返回 true 显示，发挥 false 不显示
  }
};

// 截图工具截取图片方法
const getData = () => {
  cropper.value.getCropData(async (data) => {
    let formData = new FormData();
    formData.append("imgBase", data);
    showCut.value = false;
    let res = await uploadImgBase(formData);
    if (res) {
      ImagesList.value.push({ url: prefix(res.data.file.url) });
      imgPath.value = res.data.file.url;
    }
  });
};

// 确定按钮
const cropper = ref(null);
const deteFn = async () => {
  // console.log(cropper.value.src);
  let formData = new FormData();
  let base = cropper.value.src;
  formData.append("imgBase", base);
  let res = await uploadImgBase(formData);
  if (res) {
    ImagesList.value.push({ url: prefix(res.data.file.url) });
    imgPath.value = res.data.file.url;
    isShowCro.value = false;
    showCut.value = false;
  }
};

// 取消按钮
const cancel = () => {
  isShowCro.value = false;
  showCut.value = false;
};

// 截图工具配置
let option = ref({
  imgSrc: "@/assets/new.png",
  autoCrop: true,
  dialogVisible: false,
  img_size: "",
  max_fuyuan_defaultSrc: [],
  // cropperAspectWH: 1, //图片剪裁宽高比例
  reader: "",
  e_photo_file: "",
  e_photo_filefile: "",
  http_cropImg: "", //带http
  cropImg: "", //默认图片不带http
});

// 图片背景base64
let isShowCro = ref(false);
function imageToBase64(file, detail) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    isShowCro.value = true;
    option.value.imgSrc = reader.result;
    // option.value.detail = detail;
  };
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

const handleCreated = (editor) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
};

const onClickLeft = () => {
  router.go(-1);
};

const onClickRight = async () => {
  let formData = new FormData();
  formData.append("case_id", route.query.case_id);
  formData.append("evaluate", selfEvalContent.value);
  formData.append("image_path", imgPath.value);
  let res = await apiEval(formData);
  if (res) {
    Toast.success("保存成功");
    router.go(-1);
  }
};
</script>
<style lang="less" scoped>
.topBar {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.editor {
  padding: 10px;
  margin-top: 50px;
}
.additional {
  margin-top: 10px;
  padding: 10px;

  .addBtn {
    margin-bottom: 10px;
  }
}

.cropper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 95%;
  height: 83%;
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.picBox {
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.fnBTn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);

  span {
    height: 100%;
    font-size: 12px;
    color: #fff;
  }
  span:nth-child(1) {
    margin-left: 10px;
  }
  span:nth-child(2) {
    margin-right: 10px;
  }
}
</style>
